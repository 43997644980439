import GolfErpAPI from "@/api/v2/GolfErpAPI";
export const PATH_SALES_PLAN = '/management/plan';

class SalesPlan {
    /** 영업기준계획 리스트 조회 */
    async getStandard(params) {
        const { data }= await GolfErpAPI.http.get(`${PATH_SALES_PLAN}/standard` ,{ params } );
        return data;
    }
    /** 저장가능 여부*/
    async validationSaveStd(params) {
        const { data }= await GolfErpAPI.http.get(`${PATH_SALES_PLAN}/validation-save-std` ,{ params } );
        return data;
    }
    /** 상품클래스 조회*/
    async getProductClass(params) {
        const { data } = await GolfErpAPI.http.get(`${PATH_SALES_PLAN}/product-class` ,{ params } );
        return data;
    }
    /** 영업기준계획 리스트 저장 */
    async saveStandard(params) {
        const { data } = await GolfErpAPI.http.put(`${PATH_SALES_PLAN}/standard` ,params);
        return data;
    }
    /** 영업기준계획 전년도 복사 */
    async copyPreYear(params) {
        const {  data } = await GolfErpAPI.http.post(`${PATH_SALES_PLAN}/copy-pre-year` ,params);
        return data;
    }


    /** 영업계획등록 월별 데이터 조회 */
    async getPlanYearList(params) {
        const { data } = await GolfErpAPI.http.get(`${PATH_SALES_PLAN}/year-list` ,{ params } );
        return data;
    }
    /** 영업계획등록 일별 데이터 조회 */
    async getPlanYmList(params) {
        const { data } = await GolfErpAPI.http.get(`${PATH_SALES_PLAN}/month-list` ,{ params } );
        return data;
    }
    /** 영업계획등록 일별 데이터 저장 */
    async fetchPlanYmList(planYmList) {
        return await GolfErpAPI.http.patch(`${PATH_SALES_PLAN}/month-list` ,planYmList );
    }
    /** 영업계획등록 일 목표 객단가 조회 */
    async getUnitPriceList(params) {
        const { data } = await GolfErpAPI.http.get(`${PATH_SALES_PLAN}/unit-price-list` ,{ params } );
        return data;
    }
    /** 영업계획등록 월간 데이타 기초데이타 생성 */
    async addPlanYmList(params) {
        const { data } = await GolfErpAPI.http.post(`${PATH_SALES_PLAN}/year-list` ,params);
        return data;
    }
    /** 영업계획등록 월간 데이타 전체 삭제 */
    async deletePlanYmList(params) {
        const { data } = await GolfErpAPI.http.delete(`${PATH_SALES_PLAN}/year-list` , {
            data: params,
        });
        return data;
    }
    /** 영업기준계획 월간 데이타 저장 */
    async patchStandard(params) {
        const { status } = await GolfErpAPI.http.patch(`${PATH_SALES_PLAN}/year-list` , params );
        return status;
    }
    /** 영업분석 계획대비 실적 조회 */
    async getSalesPlanResult(params) {
        const { data } = await GolfErpAPI.http.get(`${PATH_SALES_PLAN}/result-list` , {params} );
        return data;
    }
    /** 영업분석 계획대비 실적 조회 */
    async getSalesPlanResultChart(params) {
        const { data } = await GolfErpAPI.http.get(`${PATH_SALES_PLAN}/result-chart` , {params} );
        return data;
    }
}
export default new SalesPlan();
